<template>
    <div class="fill-height">
        <div v-if="ready" class="fill-height">
            <!-- Header -->
            <v-app-bar app flat dark color="purple darken-3">
                <v-app-bar-nav-icon
                    @click.stop="drawer = !drawer"
                ></v-app-bar-nav-icon>

                <v-toolbar-title>{{ $env.VUE_APP_ADMIN_H1 }}</v-toolbar-title>

                <v-spacer />

                <v-btn
                    dark
                    class="mr-2"
                    outlined
                    @click="toggleMergedDECVisibility"
                    title="Отображение объединенных ЦОК"
                >
                    <span class="mr-1">{{showMergedDEC ? 'Вкл' : 'Выкл'}}</span>
                    <v-divider vertical class="mx-2" />
                    <v-icon>mdi-merge</v-icon>
                </v-btn>

                <v-btn
                    v-if="allowEdit"
                    icon
                    dark
                    href="/build"
                    class="mr-2"
                >
                    <v-icon>mdi-puzzle-plus</v-icon>
                </v-btn>

                <v-btn
                    v-if="allowEdit"
                    icon
                    dark
                    href="/sync"
                    class="mr-2"
                >
                    <v-icon>mdi-table-arrow-up</v-icon>
                </v-btn>

                <v-menu
                    v-if="allowEdit"
                    offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            icon 
                            dark 
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                        >
                            <v-icon>mdi-arrange-send-to-back</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item link href="/transfers/export">
                            <v-list-item-title>Экспорт ЦОК для переноса</v-list-item-title>
                        </v-list-item>
                        <v-list-item link href="/transfers/import">
                            <v-list-item-title>Импорт ЦОК</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <v-btn
                    v-if="allowEdit"
                    outlined
                    color="white"
                    :disabled="true || eduCoursesSyncTotal <= 0"
                    @click.prevent.stop="sync($event)"
                >
                    <v-icon left>mdi-cloud-sync-outline</v-icon>
                    <v-badge
                        :value="eduCoursesSyncTotal > 0"
                        color="red"
                        :content="eduCoursesSyncTotal"
                    >
                        <span>Выгрузить на сервер</span>
                    </v-badge>
                </v-btn>

                <v-divider vertical class="mx-4" />

                <v-btn icon dark @click.prevent.stop="logout">
                    <v-icon>mdi-exit-to-app</v-icon>
                </v-btn>
            </v-app-bar>
            <!-- END Header -->

            <!-- Burger Sidebar -->
            <v-navigation-drawer v-model="drawer" absolute temporary>
                <v-list nav dense>
                    <v-subheader>Управление данными</v-subheader>
                    <v-list-item-group
                        active-class="deep-purple--text text--accent-4"
                        class="ml-3"
                    >
                        <v-list-item disabled>
                            <v-list-item-icon>
                                <v-icon>mdi-account-multiple</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Пользователи</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            :disabled="!allowEdit"
                            href="/commands"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-progress-wrench</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Спец. команды</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>

                    <v-subheader>Поддержка</v-subheader>
                    <v-list-item-group
                        active-class="deep-purple--text text--accent-4"
                        class="ml-3"
                    >
                        <v-list-item
                            :disabled="!allowSupport"
                            href="/support?cat_id=1"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-comment-account-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Тех. поддержка</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            :disabled="!allowSupport"
                            href="/support?cat_id=2"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-comment-account-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Метод. поддержка</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>

                    <v-subheader>Выгрузки</v-subheader>
                    <v-list-item-group
                        active-class="deep-purple--text text--accent-4"
                        class="ml-3"
                    >
                        <v-list-item
                            :disabled="!allowEdit"
                            @click.prevent.stop="downloadDecCSV"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-file-delimited-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title
                                >Ссылки на ЦОКи</v-list-item-title
                            >
                        </v-list-item>
                        <v-list-item
                            :disabled="!allowEdit"
                            @click.prevent.stop="downloadDecsFullCSV"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-file-delimited-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title
                                >Сводка по ЦОКам</v-list-item-title
                            >
                        </v-list-item>
                    </v-list-item-group>

                    <v-subheader>Статистика</v-subheader>
                    <v-list-item-group
                        active-class="deep-purple--text text--accent-4"
                        class="ml-3"
                    >
                        <v-list-item
                            :disabled="!allowEdit"
                            href="/metrics"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-chart-line</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Посещения</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            :disabled="!allowEdit"
                            href="/reports"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-chart-line</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Отчёты для educont</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            :disabled="!allowEdit"
                            href="/stats"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-chart-line</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Стат. educont</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
            <!-- END Burger Sidebar -->
            
            <v-container fluid class="fill-height pt-0 pb-0">
                <v-row
                    justify="start"
                    align="stretch"
                    class="fill-height overflow-hidden"
                >
                    <v-col
                        :cols="4"
                        class="
                            fill-height
                            border-right-1
                            d-flex
                            flex-column flex-nowrap
                            justify-start
                            align-stretch
                        "
                    >
                        <div class="flex-grow-0">
                            <v-select
                                dense
                                :value="eduCoursesVersion"
                                :items="eduCourseModelVersionList"
                                label="Дерево ЦОК для варианта контента:"
                                outlined
                                color="purple darken-3"
                                @change="changeEduCourseVersion"
                            />
                        </div>

                        <digital-edu-course-tree
                            ref="tree"
                            :items="eduCoursesTree"
                            :hide-merged="!showMergedDEC"
                            class="overflow-auto flex-grow-1"
                        />
                        <div v-if="allowEdit" class="flex-grow-0">
                            <v-btn
                                dark
                                block
                                color="purple darken-3"
                                class="mb-2"
                                @click.prevent.stop="create"
                            >
                                <v-icon class="mr-1">mdi-plus-circle-outline</v-icon>
                                Добавить новый ЦОК
                            </v-btn>
                            <v-btn
                                dark
                                block
                                color="purple darken-3"
                                class="mb-2"
                                @click.prevent.stop="importData"
                            >
                                <v-icon class="mr-1">mdi-upload</v-icon>
                                Импортировать ЦОК
                            </v-btn>
                            <v-btn
                                dark
                                block
                                color="purple darken-3"
                                @click.prevent.stop="merge"
                            >
                                <v-icon class="mr-1">mdi-merge</v-icon>
                                Слияние ЦОК
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col :cols="8" class="overflow-auto fill-height">
                        <v-row class="fill-height">
                            <v-col v-if="!_.isNil(eduCourseModel)" class="editor__sidebar-versions border-right-1">
                                <div v-for="(item, i) in eduCoursesVersions" :key="item.value">
                                    <v-divider v-if="i > 0" class="my-2" />
                                            
                                    <v-btn 
                                        dark
                                        tile
                                        :icon="!item.active"
                                        block 
                                        color="purple darken-3"
                                        :disabled="item.disabled"
                                        :class="{'untouchable': item.active}"
                                        class="px-2"
                                        @click.prevent.stop="selectVersion(item.id)"
                                    >
                                        <v-icon v-html="item.icon"></v-icon>
                                    </v-btn>
                                </div>
                            </v-col>

                            <v-col :cols="false">
                                <digital-edu-course-form
                                    class="flex-1"
                                    generator
                                    :model="eduCourseModel"
                                    @success="afterSave"
                                    @fetch="fetch"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>

            <!-- Sync dialog -->
            <v-dialog v-model="dialog.sync.show" persistent max-width="768">
                <v-card>
                    <v-card-title class="text-h5">
                        Выгрузка на сервер
                    </v-card-title>
                    <v-card-text>
                        <div>
                            <div>
                                Для выгрузки данных на сервер нажмите
                                "Продолжить".
                            </div>
                            <div class="text--secondary text-caption">
                                Выгрузка может занять продолжительное время, в
                                зависимости от объема выгружаеммых данных.
                            </div>
                        </div>
                        <v-progress-linear
                            v-if="dialog.sync.loading"
                            indeterminate
                            color="purple darken-3"
                            class="my-3"
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            dark
                            color="purple darken-3"
                            :disabled="dialog.sync.loading"
                            @click="sync($event, false)"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                            color="purple darken-3"
                            text
                            :loading="dialog.sync.loading"
                            @click="sync($event)"
                        >
                            Продолжить
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- END Sync dialog -->

            <!-- Import dialog -->
            <v-dialog v-model="dialog.import.show" persistent max-width="480">
                <v-card>
                    <v-card-title class="text-h5"> Импорт ЦОК </v-card-title>
                    <v-card-text>
                        <v-progress-linear
                            v-if="dialog.import.loading"
                            :value="dialog.import.progress"
                            color="purple darken-3"
                            height="25"
                            class="mb-1"
                        >
                            <template v-slot:default="{ value }">
                                <strong
                                    >Файл загружен на {{ value }}%{{
                                        value >= 100 ? ", обработка данных" : ""
                                    }}</strong
                                >
                            </template>
                        </v-progress-linear>
                        <v-alert
                            v-if="dialog.import.progress >= 100"
                            type="info"
                            dense
                        >
                            Обработка данных может занять несколько минут,
                            пожалуйств подождите...
                        </v-alert>

                        <v-alert
                            v-if="!_.isNil(dialog.import.error)"
                            type="error"
                        >
                            {{ dialog.import.error }}
                        </v-alert>
                        <v-radio-group
                            v-model="dialog.import.type"
                            :disabled="dialog.import.loading"
                        >
                            <template v-slot:label> Тип импорта: </template>
                            <v-radio
                                label="Как дерево элементов"
                                value="tree"
                            ></v-radio>
                            <v-radio
                                label="Как один элемент"
                                value="single"
                            ></v-radio>
                        </v-radio-group>

                        <div>
                            <v-select
                                v-model="dialog.import.version"
                                :items="eduCourseModelVersionList"
                                :menu-props="{
                                    maxHeight: 480
                                }"
                                label="Вариант контента"
                                persistent-hint
                                persistent-placeholder
                                :return-object="false"
                            >
                            </v-select>
                        </div>

                        <v-radio-group hide-details>
                            <template v-slot:label>
                                Архив с контентом:
                            </template>
                        </v-radio-group>
                        <v-file-input
                            v-model="dialog.import.file"
                            accept=".zip"
                            label="Выберите архив с данными ЦОК"
                            outlined
                            dense
                            :disabled="dialog.import.loading"
                        ></v-file-input>

                        <v-radio-group hide-details>
                            <template v-slot:label>
                                Дополнительно для ЦОК:
                            </template>
                        </v-radio-group>

                        <div class="form-group">
                            <div>
                                <v-select
                                    v-model="dialog.import.subject"
                                    :disabled="dialog.import.loading"
                                    :items="[{
                                        text: 'Не переопределять',
                                        value: ''
                                    },
                                        ..._.map($store.getters['app/eduCoursesTagsOfGroup']('A5'), o => {
                                        return {
                                            text: o.name,
                                            value: o.name
                                        }
                                    })]"
                                    :menu-props="{
                                        maxHeight: 480
                                    }"
                                    label="Предмет"
                                    persistent-hint
                                    persistent-placeholder
                                    :return-object="false"
                                >
                                </v-select>
                            </div>

                            <div>
                                <div class="form-group">
                                    <v-select
                                        v-model="dialog.import.grade"
                                        :disabled="dialog.import.loading"
                                        :items="[{
                                            text: 'Не переопределять',
                                            value: ''
                                        },
                                        ..._.map(Array(11), (v,k) => {
                                            return {
                                                text: `${k + 1} класс`,
                                                value: k + 1
                                            }
                                        })]"
                                        :menu-props="{
                                            maxHeight: 480
                                        }"
                                        label="Класс"
                                        persistent-hint
                                        persistent-placeholder
                                        :return-object="false"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- Course image -->
                        <div class="form-group">
                            <label
                                class="
                                    v-label v-label--active v-label--dense
                                    theme--light
                                "
                                >Изображение</label
                            >
                            <v-img
                                v-if="_.size(dialog.import.courseImage) > 0"
                                :src="`data:image/png;base64,${dialog.import.courseImage}`"
                                :max-width="250"
                                :max-height="250"
                            />
                            <label
                                for="image-upload-input"
                                class="
                                    d-flex
                                    flex-row flex-nowrap
                                    justify-start
                                    align-center
                                    cursor-pointer
                                "
                            >
                                <v-file-input
                                    hide-input
                                    show-size
                                    prepend-icon="mdi-image-plus"
                                    accept="image/png"
                                    class="flex-grow-0 pt-0 mt-0"
                                    id="image-upload-input"
                                    @change="uploadImage($event)"
                                ></v-file-input>
                                <span class="text-button"
                                    >Загрузить новое изображение</span
                                >
                            </label>
                        </div>
                        <!-- Course description -->
                        <div class="form-group">
                            <v-textarea
                                v-model="dialog.import.courseDescription"
                                dense
                                rows="3"
                                label="Описание"
                                clearable
                                clear-icon="mdi-close-circle"
                                persistent-placeholder
                                persistent-hint
                                no-resize
                            ></v-textarea>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            dark
                            color="purple darken-3"
                            :loading="dialog.import.loading"
                            @click="importData"
                        >
                            Загрузить
                        </v-btn>
                        <v-btn
                            v-if="!dialog.import.loading"
                            dark
                            color="purple darken-3"
                            @click="dialog.import.show = false"
                        >
                            Закрыть
                        </v-btn>
                        <v-spacer />
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- END Import dialog -->

            <!-- Merge dialog -->
            <v-dialog v-model="dialog.merge.show" persistent max-width="75vw">
                <v-card>
                    <v-card-title class="text-h5"> Слияние ЦОК </v-card-title>
                    <v-card-text>
                        <v-progress-linear
                            v-if="dialog.merge.loading"
                            :value="100"
                            color="purple darken-3"
                            height="25"
                            class="mb-1"
                        >
                            <template v-slot:default>
                                <strong>Пожалуйста, подождите...</strong>
                            </template>
                        </v-progress-linear>

                        <div :class="{ unavailable: dialog.merge.loading }">
                            <div>
                                <v-select
                                    v-model="dialog.merge.form.version"
                                    :items="eduCourseModelVersionList"
                                    :menu-props="{
                                        maxHeight: 480
                                    }"
                                    label="Вариант контента"
                                    persistent-hint
                                    persistent-placeholder
                                    :return-object="false"
                                >
                                </v-select>
                            </div>

                            <!-- Digital edu content -->
                            <v-radio-group hide-details>
                                <template v-slot:label>
                                    Выберите ЦОК для слияния:
                                </template>
                            </v-radio-group>
                            <v-data-table
                                :headers="dialog.merge.form.elements.headers"
                                :items="dialog.merge.form.elements.items"
                                hide-default-footer
                                :items-per-page="99999"
                                class="elevation-1"
                            >
                                <template v-slot:[`item.extraSort`]="{ item }">
                                    <v-select
                                        v-model="item.extraSort"
                                        :items="dialog.merge.form.extraSort"
                                        :menu-props="{
                                            maxHeight: 480,
                                        }"
                                        persistent-hint
                                        persistent-placeholder
                                        :return-object="false"
                                        :disabled="!item.selected"
                                    >
                                    </v-select>
                                </template>
                                <template v-slot:[`item.selected`]="{ item }">
                                    <v-simple-checkbox
                                        v-model="item.selected"
                                    ></v-simple-checkbox>
                                </template>
                            </v-data-table>

                            <!-- New digital edu content -->
                            <v-radio-group hide-details>
                                <template v-slot:label>
                                    Укажите необходимые данные для объединенного
                                    ЦОК:
                                </template>
                            </v-radio-group>
                            <div class="form-group">
                                <v-text-field
                                    v-model="dialog.merge.form.rootElement.name"
                                    dense
                                    label="Название"
                                    persistent-placeholder
                                ></v-text-field>
                            </div>
                            <div class="form-group">
                                <v-select
                                    v-model="
                                        dialog.merge.form.rootElement.subject
                                    "
                                    :items="dialog.merge.form.subjects"
                                    :menu-props="{
                                        maxHeight: 480,
                                    }"
                                    label="Предмет"
                                    persistent-hint
                                    persistent-placeholder
                                    :return-object="false"
                                >
                                </v-select>
                            </div>
                            <div class="form-group">
                                <v-select
                                    v-model="
                                        dialog.merge.form.rootElement.grade
                                    "
                                    :items="dialog.merge.form.grades"
                                    :menu-props="{
                                        maxHeight: 480,
                                    }"
                                    label="Класс"
                                    persistent-hint
                                    persistent-placeholder
                                    :return-object="false"
                                >
                                </v-select>
                            </div>

                            <!-- New digital edu content -->
                            <v-radio-group hide-details>
                                <template v-slot:label>
                                    <div
                                        class="
                                            d-flex
                                            flex-row flex-nowrap
                                            justify-space-between
                                            align-center
                                        "
                                    >
                                        <span
                                            >Добавьте в объединенный ЦОК
                                            дополнительную вложенность
                                            (необязательно):</span
                                        >
                                        <v-btn
                                            icon
                                            @click.prevent.stop="
                                                dialog.merge.form.tree.push({
                                                    name: '',
                                                })
                                            "
                                        >
                                            <v-icon>mdi-plus-circle</v-icon>
                                        </v-btn>
                                    </div>
                                </template>
                            </v-radio-group>
                            <div class="form-group">
                                <div
                                    v-for="(treeItem, j) in dialog.merge.form
                                        .tree"
                                    :key="j"
                                >
                                    <v-text-field
                                        v-model="treeItem.name"
                                        dense
                                        label="Название подраздела"
                                        persistent-placeholder
                                    >
                                        <template v-slot:append-outer>
                                            <v-btn
                                                icon
                                                @click.prevent.stop="
                                                    dialog.merge.form.tree.splice(
                                                        j,
                                                        1
                                                    )
                                                "
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </div>
                                <div
                                    v-if="_.size(dialog.merge.form.tree) <= 0"
                                    class="text--secondary"
                                >
                                    Дополнительная вложенность отсутствует.
                                </div>
                            </div>

                            <v-alert
                                v-if="dialog.merge.error"
                                type="error"
                                class="mt-2"
                            >
                                {{ dialog.merge.error }}
                            </v-alert>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            dark
                            color="purple darken-3"
                            :loading="dialog.merge.loading"
                            @click="merge"
                        >
                            Объединить
                        </v-btn>
                        <v-btn
                            v-if="!dialog.merge.loading"
                            dark
                            color="purple darken-3"
                            @click="dialog.merge.show = false"
                        >
                            Закрыть
                        </v-btn>
                        <v-spacer />
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- END Merge dialog -->
        </div>
        <!-- Loading icon -->
        <div
            v-else
            class="
                wv-100
                vh-100
                fill-height
                d-flex
                flex-row
                justify-center
                align-center
            "
        >
            <v-progress-circular
                :size="70"
                :width="7"
                color="purple darken-3"
                indeterminate
            />
        </div>

        <v-overlay :value="loading">
            <v-progress-circular
                indeterminate
                size="64"
                color="purple darken-3"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DigitalEduCourseTree from "@/components/editor/DigitalEduCourseTree.vue";
import DigitalEduCourseForm from "@/components/editor/DigitalEduCourseForm.vue";

const TREE_FIELDS =
    "id,created_at_date,version,allVersions,externalId,externalParentId,lft,courseName,courseTypeId,sync,shortCustomUrl,isMerge,merge";
export default {
    name: "Home",
    components: { DigitalEduCourseTree, DigitalEduCourseForm },
    data() {
        return {
            ready: false,
            drawer: false,
            showMergedDEC: false,
            dialog: {
                sync: {
                    show: false,
                    loading: false,
                    error: null,
                },
                merge: {
                    show: false,
                    loading: false,
                    error: null,
                    form: {
                        version: 'public',
                        elements: {
                            headers: [
                                {
                                    text: "ЦОК",
                                    value: "courseName",
                                    sortable: false,
                                },
                                {
                                    text: "Индекс сортировки",
                                    value: "extraSort",
                                    width: 1,
                                    align: "center",
                                    sortable: false,
                                },
                                {
                                    text: "",
                                    value: "selected",
                                    width: 1,
                                    align: "center",
                                    sortable: false,
                                },
                            ],
                            items: [],
                        },
                        subjects: [],
                        grades: [],
                        extraSort: [],
                        rootElement: {
                            name: "Новый ЦОК",
                            subject: "",
                            grade: 1,
                        },
                        tree: [],
                    },
                },
                import: {
                    show: false,
                    loading: false,
                    progress: 0,
                    file: null,
                    version: 'public',
                    type: "tree", // or 'single'
                    subject: '',
                    grade: '',
                    courseDescription: "",
                    courseImage: "",
                    error: null,
                },
            },
        };
    },
    computed: {
        ...mapState("app", ["loading", "eduCoursesSyncTotal", "eduCoursesVersion"]),
        ...mapGetters("app", ["eduCoursesTree", "eduCourseModel", "eduCourseModelVersionList"]),
        ...mapGetters("user", ["allowEdit", "allowSupport"]),
        fetchFilter() {
            return [
                "AND", 
                ["=", "courseTypeId", 0],
                ["=", "version", this.eduCoursesVersion],
            ];
        },
        eduCoursesVersions() {
            let items = [{
                value: 'public',
                icon: 'mdi-earth',
                disabled: true,
                active: false,
                id: 0
            },{
                value: 'unpublished',
                icon: 'mdi-briefcase-edit-outline',
                disabled: true,
                active: false,
                id: 0
            },{
                value: 'ministry',
                icon: 'mdi-tie',
                disabled: true,
                active: false,
                id: 0
            }];

            if(!_.isNil(this.eduCourseModel))
            {
                const versions = _.get(this.eduCourseModel, 'allVersions', []);
                if(_.size(versions) > 0)
                {
                    items = _.map(items, o => {
                        const version = _.find(versions, {version: o.value});
                        if(version)
                        {
                            o.id = version.id;
                            o.disabled = false;
                            o.active = this.eduCourseModel.version === version.version;
                        }

                        return o;
                    });
                }
            }

            return items;
        }
    },
    methods: {
        ...mapActions("user", ["logout"]),
        ...mapActions("app", ["sync"]),
        async changeEduCourseVersion(value) {
            await this.$store.dispatch('app/toggleLoading', true);
            await this.$store.dispatch("app/toggleEduCourseVersion", value);
            await this.$store.dispatch("app/fetch", {
                filter: this.fetchFilter,
                fields: TREE_FIELDS,
            });
            this.$store.dispatch('app/toggleLoading', false);
        },
        async selectVersion(id) {
            if(_.size(id) <= 0)
                return ;

            await this.$store.dispatch('app/toggleLoading', true);
            await this.$store.dispatch('app/fetch', {
                filter: ['=', 'id', id]
            });
            await this.$store.dispatch("app/updateEduCourse", _.parseInt(id));
            await this.$store.dispatch('app/toggleLoading', false);
        },
        toggleMergedDECVisibility () {
            this.showMergedDEC = !this.showMergedDEC;
        },
        create() {
            this.$refs.tree.reset();
            this.$nextTick(() => {
                this.$store.dispatch("app/updateEduCourse", {
                    data: {
                        isNewRecord: true,
                        courseTypeId:
                            this.$const.eduCourse.type.DIGITAL_EDU_CONTENT,
                    },
                });
            });
        },
        async fetchMergeItems() {
            const { data } = await this.$store.dispatch(
                "app/list",
                {
                    filter: [
                        "AND", 
                        ["=", "courseTypeId", 0],
                        ["=", "version", this.dialog.merge.form.version]
                    ],
                    fields: "id,externalId,courseName",
                }
            );
            this.dialog.merge.form.elements.items = _.map(
                _.get(data, "items", []),
                (o) => {
                    o["selected"] = false;
                    o["extraSort"] = 1;
                    return o;
                }
            );
        },
        async merge() {
            this.dialog.merge.error = null;

            if (this.dialog.merge.show !== true) {
                await this.fetchMergeItems();

                this.dialog.merge.form.subjects = _.map(
                    this.$store.getters["app/eduCoursesTagsOfGroup"]("A5"),
                    (o) => {
                        return {
                            text: o.name,
                            value: o.name,
                        };
                    }
                );
                this.dialog.merge.form.grades = _.map(Array(16), (v, k) => {
                    const n = k + 1;
                    return {
                        text: `${n <= 11 ? n : n - 11} ${
                            n <= 11 ? "класс общеобразовательной школы" : "курс"
                        }`,
                        value: n,
                    };
                });
                this.dialog.merge.form.extraSort = _.map(Array(30), (v, k) => {
                    const n = k + 1;
                    return {
                        text: n,
                        value: n,
                    };
                });

                this.dialog.merge.show = true;
                return;
            }

            // Prepare form
            const form = {
                version: this.dialog.merge.form.version,
                items: _.map(
                    _.filter(this.dialog.merge.form.elements.items, {
                        selected: true,
                    }),
                    (o) => {
                        o["extraSort"] = _.padStart(o.extraSort, 4, "0");
                        return _.pick(o, ["id", "extraSort"]);
                    }
                ),
                rootElement: _.cloneDeep(this.dialog.merge.form.rootElement),
                tree: _.map(this.dialog.merge.form.tree, "name"),
            };
            // Validate
            const validation = [
                _.size(form.items) <= 0,
                _.size(form.rootElement.name) <= 0,
                _.size(form.rootElement.subject) <= 0,
                _.size(form.rootElement.grade.toString()) <= 0,
                _.size(_.compact(form.tree)) !== _.size(form.tree),
            ];

            if (_.size(_.compact(validation)) > 0) {
                this.dialog.merge.error =
                    "В форме есть неверно заполненные поля. Проверьте заполнение формы, исправьте ошибки и попробуйте еще раз.";
                return;
            } else {
                // Start
                this.dialog.merge.loading = true;
                const { success, data, error } = await this.$store.dispatch(
                    "app/merge",
                    form
                );
                if (!success) this.dialog.merge.error = error;
                else {
                    await this.$store.dispatch("app/fetch", {
                        filter: [
                            ...this.fetchFilter,
                            [">=", "id", _.get(data, "startId", 0)],
                        ],
                        fields: TREE_FIELDS,
                    });
                    this.dialog.merge.show = false;
                }

                this.dialog.merge.loading = false;
            }
        },
        async sync(e, value) {
            // Cancel
            if (value === false) {
                this.dialog.sync = {
                    show: false,
                    loading: false,
                    error: null,
                };
                return;
            }
            // Show
            if (this.dialog.sync.show !== true) {
                this.dialog.sync.show = true;
                return;
            }
            // Handler
            this.dialog.sync.loading = true;
            const { success, error } = await this.$store.dispatch("app/syncAll");
            this.dialog.sync.loading = false;

            if (success) {
                this.$store.commit("app/UPDATE_EDU_COURSE_SYNC_TOTAL", 0);
                this.dialog.sync = {
                    show: false,
                    loading: false,
                    error: null,
                };
            } else this.dialog.sync.error = error;
        },
        async importData() {
            this.dialog.import.error = null;
            this.progress = 0;

            if (this.dialog.import.show !== true) {
                this.dialog.import.file = null;
                this.dialog.import.show = true;
                return;
            }

            // Validate
            if (_.isNil(this.dialog.import.file)) {
                this.dialog.import.error =
                    "Необходимо выбрать архив с контентом.";
                return;
            }

            this.dialog.import.loading = true;
            const { success, data, error } = await this.$store.dispatch(
                "app/import",
                {
                    ..._.pick(this.dialog.import, [
                        "file",
                        "version",
                        "type",
                        "subject",
                        "grade",
                        "courseImage",
                        "courseDescription",
                    ]),
                    onProgress: (val) => {
                        this.dialog.import.progress = val;
                    },
                }
            );
            if (!success) this.dialog.import.error = error;
            else {
                await this.$store.dispatch("app/fetch", {
                    filter: [
                        ...this.fetchFilter,
                        [">=", "id", _.get(data, "startId", 0)],
                    ],
                    fields: TREE_FIELDS,
                });
                this.dialog.import.show = false;
                this.dialog.import.file = null;
            }

            this.dialog.import.loading = false;
        },
        async uploadImage(e) {
            // Validate type & size
            if (e.type !== "image/png" || e.size > 512000) {
                this.dialog.import.error =
                    "Для загрузки разрешены только изображения в формате .PNG размером не более 500Кб";
                return;
            }

            const reader = new FileReader();
            reader.readAsBinaryString(e);

            reader.onload = (event) => {
                this.dialog.import.courseImage = btoa(event.target.result);
            };

            reader.onerror = () => {
                this.dialog.import.error = "Ошибка при загрузке изображения.";
            };
        },
        afterSave(id) {
            this.$refs.tree.reset();
            this.$refs.tree.selected = [id];
            this.$nextTick(() => {
                this.$store.dispatch("app/updateEduCourse", id);
            });
        },
        async logout() {
            await this.$store.dispatch("user/logout");
            window.location.reload();
            return;
        },
        downloadDecCSV() {
            const rows = _.map(this.eduCoursesTree, (o) => {
                let shortUrl = `${window.location.origin}${o.shortCustomUrl}`;
                let externalLink = `${window.location.origin}/digital-content/${o.externalId}`;
                return _.join([o.courseName, shortUrl, externalLink], ";");
            });
            const csv = _.join(
                [
                    _.join(
                        ["ЦОК", "Короткая ссылка", "Ссылка для Инополиса"],
                        ";"
                    ),
                    ...rows,
                ],
                "\r\n"
            );

            let fileName = "Ссылки_на_ЦОКи.csv";
            let file = new File(["\uFEFF" + csv], fileName, {
                type: "text/csv;charset=utf-8",
            });
            let exportUrl = URL.createObjectURL(file);
            window.location.assign(exportUrl);
            URL.revokeObjectURL(exportUrl);
        },
        getTagName (tagId) {
            return this.$store.state.app?.eduCoursesTags?.find(tag => tag.id === tagId)?.name || 'NONE';
        },
        promiseArray (value) {
            if (Array.isArray(value)) { return value; }
            return [value];
        },
        async downloadDecsFullCSV() {
            const separator = prompt('Укажите символ разделитель для столбцов.', '~');
            try {
                await this.$store.dispatch('app/toggleLoading', true);
                this.drawer = false;
                const { success, data, error } = await this.$store.dispatch('app/list', {
                    filter: ['=', 'courseTypeId', 0],
                    fields: 'id,courseName,data'
                });
                if (!success) {
                    alert(error)
                    return
                }
                const items = data?.items || [];
                const rows = _.map(items, (o) => {
                    // Iterating from A1 to A10
                    const tags = new Array(10).fill(null).map((_item, _index) => {
                        if (!o.data?.tagsByGroups) { return 'NONE'; }
                        return this.promiseArray(o.data.tagsByGroups[`A${_index+1}`])
                                .map(tagId => this.getTagName(tagId))
                                .join('|')
                    });
                    return _.join(
                        [ o.id, o.courseName, o.data?.courseDescription.replaceAll("\n", '').replaceAll("\r", ''), ...tags ],
                        separator
                    );
                });

                const csv = _.join(
                    [
                        _.join(
                            [
                                "ЦОК ID",
                                "Наименование",
                                "Описание",
                                // Наименования групп тегов
                                ...Object.keys(this.$const.eduCourse.tags.groupLabels)
                                    .map(
                                        label => this.$const.eduCourse.tags.groupLabels[label]
                                    )
                            ],
                            separator
                        ),
                        ...rows,
                    ],
                    "\r\n"
                );

                let fileName = "Список_ЦОКов.csv";
                let file = new File(["\uFEFF" + csv], fileName, {
                    type: "text/csv;charset=utf-8",
                });
                let exportUrl = URL.createObjectURL(file);
                window.location.assign(exportUrl);
                URL.revokeObjectURL(exportUrl);
            } catch (e) {
                console.error(e);
            } finally {
                this.$store.dispatch('app/toggleLoading', false);
            }
        },
        async fetch(id) {
            await this.$store.dispatch("app/fetch", {
                filter: this.fetchFilter,
                fields: TREE_FIELDS,
            });
            if(!_.isNil(id))
            {
                await this.$store.dispatch('app/fetch', {
                    filter: ['=', 'id', id]
                });
            }
        }
    },
    async created() {
        // Load digital edu content tree
        if (this.$store.getters["user/isAuthorized"] === true) {
            await this.$store.dispatch("app/fetchTags");
            await this.fetch();
        }

        this.ready = true;
    },
    watch: {
        'dialog.merge.form.version'() {
            this.fetchMergeItems();
        }
    }
};
</script>

<style lang="scss">
.page-main.authorized:not(.educont-user) {
    height: 100vh;
}
</style>

<style lang="scss" scoped>
.editor__sidebar-versions{
    width: 64px;
    flex: 0 0 64px;
}
</style>